const Parse = require('parse');

const SERVER_URL = 'https://parseapi.back4app.com/';
const APP_ID = 'XtBNlNaWaad23Wrtg8MTYbhB7qVpA4u0FiZCd5PI';
const JAVASCRIPT_KEY = 'l5PGs6ce2hl6hWAGTkVRowt9OWvNjFl1cEfO9GJr';
Parse.initialize(APP_ID, JAVASCRIPT_KEY);
Parse.serverURL = SERVER_URL;

var currentUser, eventsDS, currentEventEditData, currentMemoryEditData, currentPhotoEditData, currentEditUI, promptsDS;

window.addEventListener('load', () => {
  document.getElementById('btn_new_e').addEventListener('click', newEvent);
  document.getElementById('btn_logout').addEventListener('click', ()=>{
    Parse.User.logOut().then(() => {
      initUser(); 
    });
  });
  initUser(); 
});

async function getPrompts(){
  const query = new Parse.Query('Prompt');
  try {
    promptsDS = await query.find();
  } catch (error) {
      throw(error);    
  }
}

async function getEvents(){
  const query = new Parse.Query('Event');
  try {
    eventsDS = await query.find();
  } catch (error) {
      throw(error);    
  }
}

const renderEvents=()=>{
  const es = document.getElementById('data_events');
  es.innerHTML = '';
  if(eventsDS.length == 0){
      es.innerHTML = 'None yet.';
      return;
  }
  for (var i=0;i<eventsDS.length;i++) {
      const d = renderEventCore(document.createElement('div'), eventsDS[i]);
      es.appendChild(d);
  }    
}

const renderEventCore=(ui, data)=>{
  ui.classList.add('card');
  var sp = document.createElement('span');
  ui.appendChild(sp);
  sp.innerHTML = '<h4>Basic Info</h4>';
  var d = document.createElement('div');
  ui.appendChild(d);
  d.classList.add('spacey-row');
  sp = document.createElement('span');
  d.appendChild(sp);
  const date = new Date(data.get('date'));
  sp.innerHTML = `${data.get('honoree')}'s ${data.get('title')} on ${date.toLocaleString('en-US', { month: 'short' })} ${date.getDate()}, "${data.get('message')}"`;
  sp = document.createElement('img');
  d.appendChild(sp);
  sp.src = data.get('bgImageUrl');
  sp.classList.add('image-preview');
  var a = document.createElement('a');
  d.appendChild(a);
  a.id = data.id;
  a.href='#';
  a.innerHTML = '✎ edit';
  a.addEventListener('click', editEvent, true);
  sp = document.createElement('span');
  ui.appendChild(sp);
  sp.innerHTML = '<h4>Photos</h4>';
  d = document.createElement('div');
  ui.appendChild(d);
  d.classList.add('spacey-row');
  a = document.createElement('a');
  d.appendChild(a);
  a.id = data.id;
  a.href='#';
  a.innerHTML = '+ new photo';
  a.addEventListener('click', newPhoto, true);
  renderPhotoPreview(d, data.get('eventPhotoIds'), data.id);
  sp = document.createElement('span');
  ui.appendChild(sp);
  sp.innerHTML = '<h4>Memories</h4>';
  d = document.createElement('div');
  ui.appendChild(d);
  d.classList.add('spacey-row');
  renderMemoryPreview(d, data.get('memoryIds'), data.id);
  a = document.createElement('a');
  d.appendChild(a);
  a.id = data.id;
  a.href='#';
  a.innerHTML = '+ new memory';
  a.addEventListener('click', newMemory, true);
  sp = document.createElement('span');
  ui.appendChild(sp);
  const input = document.createElement('input');
  sp.appendChild(input);
  input.id = `input_copyLink_${data.id}`;
  input.type = 'text';
  input.value = `https://memorybook.app/book.html?id=${data.id}`;
  a = document.createElement('a');
  sp.appendChild(a);
  a.id = data.id;
  a.href='#';
  a.id = data.id;
  a.innerHTML = 'copy share link';
  a.addEventListener('click', copyShareLink, true);
  return ui;
}

const newEvent=()=>{
  currentEditUI = document.createElement('div');
  currentEditUI.classList.add('shim');
  document.body.appendChild(currentEditUI);
  const d = document.createElement('div');
  currentEditUI.appendChild(d);
  d.classList.add('card');
  var label = document.createElement('label');
  d.appendChild(label);
  label.innerHTML = 'Event Title';
  var input = document.createElement('input');
  d.appendChild(input);
  input.id = 'input_title';
  input.type = 'text';
  input.value = currentEventEditData ? currentEventEditData.get('title') : '';
  label = document.createElement('label');
  d.appendChild(label);
  label.innerHTML = 'Honoree';
  input = document.createElement('input');
  d.appendChild(input);
  input.id = 'input_honoree';
  input.type = 'text';
  input.value = currentEventEditData ? currentEventEditData.get('honoree') : '';
  label = document.createElement('label');
  d.appendChild(label);
  label.innerHTML = 'Date';
  input = document.createElement('input');
  d.appendChild(input);
  input.id = 'input_date';
  input.type = 'date';
  input.valueAsDate = currentEventEditData ? new Date(currentEventEditData.get("date")) : new Date();
  label = document.createElement('label');
  d.appendChild(label);
  label.innerHTML = 'Message';
  input = document.createElement('textarea');
  d.appendChild(input);
  input.id = 'input_message';
  input.rows = '4';
  input.value = currentEventEditData ? currentEventEditData.get('message') : '';
  label = document.createElement('label');
  d.appendChild(label);
  label.innerHTML = 'Background Image Url';
  var input = document.createElement('input');
  d.appendChild(input);
  input.id = 'input_bg';
  input.type = 'text';
  input.value = currentEventEditData ? currentEventEditData.get('bgImageUrl') : '';
  const d2 = document.createElement('div');
  d.appendChild(d2);
  d2.classList.add('button_row');
  input = document.createElement('input');
  d2.appendChild(input);
  input.type = 'button';
  input.value = 'cancel';
  input.onclick = resetEventUI;
  if(currentEventEditData){
      input = document.createElement('input');
      input.id = currentEventEditData.id;
      d2.appendChild(input);
      input.type = 'button';
      input.value = 'delete';
      input.onclick = deleteEvent;    
  }
  input = document.createElement('input');
  d2.appendChild(input);
  input.type = 'button';
  input.value = 'save';
  input.onclick = saveEvent;
};

const resetEventUI=()=>{
  currentEditUI.parentNode.removeChild(currentEditUI);
  currentEditUI = null;
  currentEventEditData = null;
};

const editEvent=(e)=>{
  currentEventEditData = eventsDS.filter(p => p.id == e.target.id)[0];
  newEvent();
};

const saveEvent=()=>{
  if(document.getElementById('input_title').value.length < 1) {
      alert("Yo, how do you expect the app to work if you don't provide an event title?");
      return;
  }
  if(document.getElementById('input_honoree').value.length < 1) {
    alert("Yo, how do you expect the app to work if you don't provide an honoree?");
    return;
  }
  if(document.getElementById('input_message').value.length < 1) {
    alert("Be nice. Include a message!");
    return;
  }

  const Event = Parse.Object.extend('Event');
  const ev = new Event();
  if(currentEventEditData){ ev.set('objectId', currentEventEditData.id); }
  if(!currentEventEditData){ 
    ev.set('memoryIds', []);
    ev.set('eventPhotoIds', []);
    ev.set('eventTypeId', ' ');//todo: add to ui
  }
  ev.set('title', document.getElementById('input_title').value);
  ev.set('honoree', document.getElementById('input_honoree').value);
  ev.set('date', document.getElementById('input_date').valueAsDate);
  ev.set('message', document.getElementById('input_message').value);
  ev.set('bgImageUrl', document.getElementById('input_bg').value);
  ev.save().then((r) => {  
      if(currentEventEditData) {replace(currentEventEditData,r,eventsDS);}
      else { eventsDS.push(r); }
      renderEvents();
      resetEventUI();
  }, (error) => {
      alert('Failed saving event: ' + error);
  });
};

  const deleteEvent=(e)=>{
    const obj = eventsDS.filter(p => p.id == e.target.id)[0];
    const memIds = obj.get('memoryIds');
    for(var i = 0;i < memIds.length;i++){
      const q = new Parse.Query('Memory');
      q.get(memIds[i]).then((mem)=>{
        mem.destroy().then(()=>{

        }, (error)=>{
          handleParseError('Error deleting Memory associated with Event', error);
        })
      }, (error)=>{
        handleParseError('Error deleting Memory associated with Event', error);
      }); 
    }
    const epIds = obj.get('eventPhotoIds');
    for(var i = 0;i < memIds.length;i++){
      const q = new Parse.Query('EventPhoto');
      q.get(epIds[i]).then((pho)=>{
        pho.destroy().then(()=>{

        }, (error)=>{
          handleParseError('Error deleting Photo associated with Event', error);
        })
      }, (error)=>{
        handleParseError('Error deleting Photo associated with Event', error);
      }); 
    }
    obj.destroy().then(()=>{
        replace(currentEventEditData,null,eventsDS);
        renderEvents();
        resetEventUI();
    },(error)=>{
        alert('Failed deleting event: ' + error);
    });
};

const copyShareLink=(e)=>{
  var copyText = document.getElementById(`input_copyLink_${e.target.id}`);
  copyText.select();
  copyText.setSelectionRange(0, 99999);
  navigator.clipboard.writeText(copyText.value).then(()=>{
    alert("Copied the text: " + copyText.value);
  }, (error)=>{
    alert("Error copying text: " + error);
  });
};

const editPhoto=(e)=>{
  const info = e.target.id.split("_");
  const photoId = info[0];
  const eventId = info[1];
  currentEventEditData = eventsDS.filter(p => p.id == eventId)[0];
  const q = new Parse.Query('EventPhoto');
  q.get(photoId).then((pho)=>{
    currentPhotoEditData = pho;
    newPhoto();
  }, (error)=>{
    handleParseError('Error when fetching Photo', error);
  });
};

const renderPhotoPreview=(container, data, ownerId)=>{
  if(data.length < 1){
    const sp = document.createElement('span');
    sp.innerHTML = 'None yet.'
    container.appendChild(sp);
  }
  else {
    for(var i=0;i<data.length;i++) {
      const query = new Parse.Query('EventPhoto');
      query.get(data[i]).then((r)=>{
        const d = document.createElement('div');
        container.appendChild(d);
        const sp = document.createElement('img');
        d.appendChild(sp);
        sp.src = r.get('photo').url();
        sp.classList.add('image-preview');
        var a = document.createElement('a');
        d.appendChild(a);
        a.id = `${r.id}_${ownerId}`;
        a.href='#';
        a.innerHTML = '✎ edit';
        a.addEventListener('click', editPhoto, true);
      });
    }
  }
}

const newPhoto=(e)=>{
  if(!currentEventEditData){ currentEventEditData = eventsDS.filter(p => p.id == e.target.id)[0]; }
  currentEditUI = document.createElement('div');
  currentEditUI.classList.add('shim');
  document.body.appendChild(currentEditUI);
  const d = document.createElement('div');
  currentEditUI.appendChild(d);
  d.classList.add('card');
  var label = document.createElement('label');
  d.appendChild(label);
  label.innerHTML = 'Photo';
  if(currentPhotoEditData){
    var im = document.createElement('img');
    d.appendChild(im);
    im.style.width = '100px';
    im.src = currentPhotoEditData.get('photo').url();
  }
  else {
    var input = document.createElement('input');
    d.appendChild(input);
    input.id = 'input_photo';
    input.accept = '.png, .jpg';
    input.type = 'file';
  }
  label.innerHTML = 'Date';
  input = document.createElement('input');
  d.appendChild(input);
  input.id = 'input_photo_date';
  input.type = 'date';
  input.valueAsDate = currentPhotoEditData ? new Date(currentPhotoEditData.get("date")) : new Date();
  label = document.createElement('label');
  d.appendChild(label);
  label.innerHTML = 'Description';
  var input = document.createElement('input');
  d.appendChild(input);
  input.id = 'input_photo_description';
  input.type = 'text';
  input.value = currentPhotoEditData ? currentPhotoEditData.get('description') : '';
  const d2 = document.createElement('div');
  d.appendChild(d2);
  d2.classList.add('spacey-row');
  input = document.createElement('input');
  d2.appendChild(input);
  input.type = 'button';
  input.value = 'cancel';
  input.onclick = cancelPhoto;
  if(currentPhotoEditData){
    input = document.createElement('input');
    d2.appendChild(input);
    input.type = 'button';
    input.value = 'delete';
    input.onclick = deletePhoto;    
}
  input = document.createElement('input');
  d2.appendChild(input);
  input.type = 'button';
  input.value = 'save';
  input.onclick = savePhoto;
};

const savePhoto=()=>{
  if(document.getElementById('input_photo_description').value.length < 1) {
    alert("Yo, a description would be nice.");
    return;
  }

  if(!currentPhotoEditData) {
    const fInput = document.getElementById('input_photo');
    if(fInput.files.length < 1) {
        alert("Yo, can't upload an image if there is not image!");
        return;
    }
    let parseFile;
    const file = fInput.files[0];
    const name = fInput.files[0].name;  
    parseFile = new Parse.File(name, file);
    parseFile.save().then((pfile)=>{
      const EventPhoto = new Parse.Object.extend('EventPhoto');
      const ep = new EventPhoto();
      ep.set('description', document.getElementById('input_photo_description').value);
      ep.set('date', document.getElementById('input_photo_date').valueAsDate);
      ep.set('photo', pfile);
      ep.save().then((p) => {  
        if(!currentPhotoEditData){
            const photos = currentEventEditData.get('eventPhotoIds');
            photos.push(p.id);
            currentEventEditData.set('eventPhotoIds', photos);
            currentEventEditData.save().then((r)=>{
              replace(currentEventEditData,r,eventsDS);
              renderEvents();
              cancelPhoto();
            }, (error)=>{
              alert('Failed updating memory: ' + error);
            });
          }
          else{
            renderEvents();
            cancelPhoto();
          }
        }, (error) => {
            alert('Failed to save image. ' + error);
        });
    }, function(error) {
      window.alert('Error while saving image', error);
    });
  }
  else {
    const EventPhoto = new Parse.Object.extend('EventPhoto');
    const ep = new EventPhoto();
    ep.set('objectId', currentPhotoEditData.id);
    ep.set('description', document.getElementById('input_photo_description').value);
    ep.set('date', document.getElementById('input_photo_date').valueAsDate);
    ep.save().then(() => {  
      renderEvents();
      cancelPhoto();
    }, (error) => {
        alert('Failed to update image. ' + error);
    });
  }
};

const cancelPhoto=()=>{
  currentEditUI.parentNode.removeChild(currentEditUI);
  currentEditUI = null;
  currentPhotoEditData = null;
  currentEventEditData = null;
};

const deletePhoto=()=>{
  const memId = currentPhotoEditData.id;
  currentPhotoEditData.destroy().then(()=>{
    const newPhotos = currentEventEditData.get('eventPhotoIds').filter(m => m != memId);
    currentEventEditData.set('eventPhotoIds', newPhotos);
    currentEventEditData.save().then(()=>{
      renderEvents();
      cancelPhoto();
    }, (error)=>{
      handleParseError('Error updating event after deleting photo.', error);
    });
  }, (error)=>{
    handleParseError('Error deleting photo.', error);
  });
};

const renderMemoryPreview=(container, data, ownerId)=>{
  if(data.length < 1){
    const sp = document.createElement('span');
    sp.innerHTML = 'None yet.'
    container.appendChild(sp);
  }
  else {
    for(var i=0;i<data.length;i++) {
      const query = new Parse.Query('Memory');
      query.get(data[i]).then((r)=>{
        const d = document.createElement('div');
        container.appendChild(d);
        d.innerHTML = `${r.get('from')} remembers "${r.get('details').substring(0,3)}..."`;
        if(currentUser.id == r.get('creatorId')){
          var a = document.createElement('a');
          d.appendChild(a);
          a.id = `${r.id}_${ownerId}`;
          a.href='#';
          a.innerHTML = '✎ edit';
          a.addEventListener('click', editMemory, true);
        }
      })
    }
  }
}

const newMemory=(e)=>{
  if(!currentEventEditData){ currentEventEditData = eventsDS.filter(p => p.id == e.target.id)[0]; }
  currentEditUI = document.createElement('div');
  currentEditUI.classList.add('shim');
  document.body.appendChild(currentEditUI);
  const d = document.createElement('div');
  currentEditUI.appendChild(d);
  d.classList.add('card');
  var label = document.createElement('label');
  d.appendChild(label);
  label.innerHTML = 'Who are you?';
  var input = document.createElement('input');
  d.appendChild(input);
  input.id = 'input_from';
  input.type = 'text';
  input.value = currentMemoryEditData ? currentMemoryEditData.get('from') : currentUser.get('username').split(' ')[0];
  const select = document.createElement('select');
  d.appendChild(select);
  select.id = 'select_prompt';
  for(var i = 0;i < promptsDS.length; i++) {
    const p = promptsDS[i];
    const option = document.createElement('option');
    option.value = p.id;
    option.text = p.get('copy').replace('[HONOREE]', currentEventEditData.get('honoree'));
    option.selected = currentMemoryEditData ? currentMemoryEditData.get('promptId') == p.id : p.get('isDefault') == true;
    select.appendChild(option);
  }
  input = document.createElement('textarea');
  d.appendChild(input);
  input.id = 'input_details';
  input.rows = '4';
  input.value = currentMemoryEditData ? currentMemoryEditData.get('details') : '';
  const d2 = document.createElement('div');
  d.appendChild(d2);
  d2.classList.add('spacey-row');
  input = document.createElement('input');
  d2.appendChild(input);
  input.type = 'button';
  input.value = 'cancel';
  input.onclick = cancelMemory;
  if(currentMemoryEditData){
      input = document.createElement('input');
      d2.appendChild(input);
      input.type = 'button';
      input.value = 'delete';
      input.onclick = deleteMemory;    
  }
  input = document.createElement('input');
  d2.appendChild(input);
  input.type = 'button';
  input.value = 'save';
  input.onclick = saveMemory;
};

const editMemory=(e)=>{
  const info = e.target.id.split("_");
  const memoryId = info[0];
  const eventId = info[1];
  currentEventEditData = eventsDS.filter(p => p.id == eventId)[0];
  const q = new Parse.Query('Memory');
  q.get(memoryId).then((mem)=>{
    currentMemoryEditData = mem;
    newMemory();
  }, (error)=>{
    handleParseError('Error when fetching Memory', error);
  });
};

const saveMemory=(e)=>{
  if(document.getElementById('input_from').value.length < 1) {
    alert("Yo, who's this memory from?");
    return;
  }
  if(document.getElementById('input_details').value.length < 1) {
    alert("Yo, how do you expect the app to work if you don't provide a memory?");
    return;
  }

  const Memory = new Parse.Object.extend('Memory');
  const mem = new Memory();
  if(currentMemoryEditData){ mem.set('objectId', currentMemoryEditData.id); }
  if(!currentMemoryEditData){ 
    mem.set('creatorId', currentUser.id);
  }
  const sel = document.getElementById('select_prompt');
  var val = sel.options[sel.selectedIndex].value;
  mem.set('promptId', val);
  mem.set('from', document.getElementById('input_from').value);
  mem.set('details', document.getElementById('input_details').value);
  mem.save().then((m) => {  
    if(!currentMemoryEditData){
      const mems = currentEventEditData.get('memoryIds');
      mems.push(m.id);
      currentEventEditData.set('memoryIds', mems);
      currentEventEditData.save().then((r)=>{
        replace(currentEventEditData,r,eventsDS);
        renderEvents();
        cancelMemory();
      }, (error)=>{
        alert('Failed updating memory: ' + error);
      });
    }
    else{
      renderEvents();
      cancelMemory();
    }
  }, (error) => {
      alert('Failed saving memory: ' + error);
  });
};

const cancelMemory=()=>{
  currentEditUI.parentNode.removeChild(currentEditUI);
  currentEditUI = null;
  currentMemoryEditData = null;
  currentEventEditData = null;
};

const deleteMemory=()=>{
  const memId = currentMemoryEditData.id;
  currentMemoryEditData.destroy().then(()=>{
    const newMems = currentEventEditData.get('memoryIds').filter(m => m != memId);
    currentEventEditData.set('memoryIds', newMems);
    currentEventEditData.save().then(()=>{
      renderEvents();
      cancelMemory();
    }, (error)=>{
      handleParseError('Error updating event after deleting memory.', error);
    });
  }, (error)=>{
    handleParseError('Error deleting memory.', error);
  });
};

const initUser=()=>{
    currentUser = Parse.User.current();
    if (currentUser) {
      document.getElementById('btn_logout').style.display = 'block';
      getPrompts().then(()=>{
        getEvents().then(()=>{
          renderEvents();
        }).catch(function(e){
            handleParseError('Error when fetching Events', e);
        });
      }, (error)=>{
        handleParseError('Error getting prompts.', error);
      });
    } else {
      var redirect = window.location.pathname.replace('/', '').replace('.html', '');
      redirect = redirect == '' ? 'index' : '';
      location.assign(`${window.location.origin}/login.html?redirect=${redirect}`);
    }
};

function handleParseError(msg, err) {
  switch (err.code) {
    case Parse.Error.INVALID_SESSION_TOKEN:
      Parse.User.logOut();
      const redirect = window.location.pathname.replace('/', '').replace('.html', '');
      window.location = `${window.location.origin}/login.html?redirect=${redirect}`;
      break;
    default:
      console.log(`${msg} : ${err.code} - ${err.message}`);
  }
};

const replace=(oldD, newD, ds)=>{
  const index = ds.indexOf(oldD);
  if(newD) {
      ds.splice(index, 1, newD);
  }
  else {
      ds.splice(index, 1);
  }
};